$(window).on('load', function () {
    var SecondaryMenu = (function SecondaryMenu($) {

        if ($('.scrolling-chapters').length < 1) {
            return false;
        }

        $(".main-content").addClass("scrolling-chapters-main-content");

        var $component;

        function attachEvents() {
            var $body = $("html, body");
            var $siteHeader = $('body').find(".site-header");
            var $menuSection = $('body').find("[data-menu]");
            var headerHeight;


            $(window).on("scroll", function () {
                var scrollTop = $(this).scrollTop();
                headerHeight = $siteHeader.outerHeight();
                $menuSection.each(function () {
                    var offstTop = $(this).offset().top - headerHeight - 50;
                    var offstBottom = $(this).offset().top - headerHeight + $(this).outerHeight() - 30;
                    if (scrollTop > offstTop && scrollTop < offstBottom) {
                        $component.find('.menu-link').removeClass('active');
                        $component.find('.menu-link[href="#' + $(this).attr('id') + '"]').addClass('active');
                    }
                });
            });

            $menuSection.each(function () {
                var href = $(this).attr('id');
                var dataTitle = $(this).data('menu');
                var MenuTab = '<a class="menu-link" href="#' + href + '">' + dataTitle + '</a>';
                $component.append(MenuTab);
            });

            $component.find(".menu-link").on("click", function (e) {
                headerHeight = $siteHeader.outerHeight();
                var isMobile = $(window).width() < 992;
                var offset = $($(this).attr("href")).offset().top;

                if (!$($body).hasClass('is-scrolled')) {
                    offset = offset + 30;
                }

                if (isMobile) {
                    $body.stop().animate({ scrollTop: offset - 30 }, 500, 'swing');
                } else {
                    $body.stop().animate({ scrollTop: offset - headerHeight - 30 }, 500, 'swing');
                }
            });

            $menuSection.on('click', '.mobile-toggle', function () {
                $(this).closest('[data-menu]').toggleClass('active');
            });
        }

        function init($componentDiv) {
            $component = $componentDiv;
            if (!$component.length) return;
            attachEvents();
        }

        return {
            init: init
        };


    }(jQuery));


    if ($('.scrolling-chapters').length < 1) {
        return false;
    }

    var $componentDiv = $('body').find('.secondary-menu-vertical');
    SecondaryMenu.init($componentDiv);

    
});