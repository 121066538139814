$(document).ready(function ($) {

    $(".article-copy ul").each(function () {
       if(this.classList.contains("fa-ul")) return;
       var bulletFound = false;
        $(this).children().each(function () {
            if (hasBulletCharacter($(this).text())) {
                bulletFound = true;
                return false;
            }
        });
        if (!bulletFound) {
            $(this).css("list-style-type", "initial");
        }
    });

    function hasBulletCharacter(str) {
        for (var i = 0; i < str.length; i++) {
            var charCode = str.charCodeAt(i);
            if (charCode === 8226) {
                return true;
            }
        }
        return false;
    }

    // scroll to and open accordion on page load
    if ($(".target-accordion").length && window.location.hash !== "") {
        var targetHash = window.location.hash
        var jsToggleContainer = $(targetHash).parents(".accordion-grid-item").find(".js-toggle-container")

        jsToggleContainer.parent().toggleClass("active")
        jsToggleContainer.parent().find('.js-slide').stop().slideToggle(350);

        if (window.innerWidth > 1200) {
            $('html, body').animate({
                scrollTop: $(targetHash).parents(".accordion-grid-item").offset().top - 130
            }, 'slow');
        }  else {
            $('html, body').animate({
                scrollTop: $(targetHash).parents(".accordion-grid-item").offset().top
            }, 'slow');
        }
        
    }

    // match card heights
    function matchHeights() {

        $(".cards-default-grid").each(function () {

            var topHeight = -1
            var bottomHeight = -1

            $(this).find('.cards-default-grid-item-wrapper').each(function () {
                topHeight = topHeight > $(this).height() ? topHeight : $(this).height();
            });

            $(this).find('.cards-default-grid-item-wrapper').each(function () {
                $(this).height(topHeight);
            });

            $(this).find('.product-spotlight').each(function () {
                bottomHeight = bottomHeight > $(this).height() ? bottomHeight : $(this).height();
            });

            $(this).find('.product-spotlight').each(function () {
                $(this).height(bottomHeight);
            });

        })

        $(".cards-feature-grid").each(function () {

            var topHeight = -1
            var bottomHeight = -1

            $(this).find('.cards-feature-item-text').each(function () {
                topHeight = topHeight > $(this).height() ? topHeight : $(this).height();
            });

            $(this).find('.cards-feature-item-text').each(function () {
                if ($(window).width() > 767) {
                    $(this).height(topHeight);
                }  
            });


        })

        $(".cards-callouts-grid").each(function () {

            var topHeight = -1
            var bottomHeight = -1

            $(this).find('.callout-with-title-image .cards-callouts-grid-item-title').each(function () {
                topHeight = topHeight > $(this).height() ? topHeight : $(this).height();
            });

            $(this).find('.callout-with-title-image .cards-callouts-grid-item-title').each(function () {
                if ($(window).width() > 767) {
                    $(this).height(topHeight);
                }
            });


        })


    }

    matchHeights()

    $(window).resize(function () {

        $('.cards-default-grid-item-wrapper').each(function () {
            $(this).height('auto');
        });

        $('.product-spotlight').each(function () {
            $(this).height('auto');
        });

        $('.cards-feature-item-text').each(function () {
            $(this).height('auto');
        });

        $('.callout-with-title-image .cards-callouts-grid-item-title').each(function () {
            $(this).height('auto');
        });

        matchHeights()

    })

    // Init Tabs
    if ($('.tab-module').length >= 1) {

        $('.tab-module').responsiveTabs({
            startCollapsed: 'accordion'
        });
    }
        // Table accordions for mobile
        $(".td").on("click", ".mobile-accordion-trigger", function (e) {
            e.preventDefault();

            var dropdown = $(this).closest(".td");
            var dropdownRow = $(this).closest(".tr");

            dropdown.toggleClass("toggle-active").siblings(".td").toggleClass("item-show");

            // Hide the siblings dropdowns and remove toggle active state        
            dropdownRow.siblings(".tr").find(".mobile-accordion-trigger").closest(".td").removeClass("toggle-active");
            dropdownRow.siblings(".tr").find(".item-show").removeClass("item-show");
        });
    
	// add isScrolled class
	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if(scroll >= 101) {
			$("body").addClass("is-scrolled");
		}
		if(scroll <= 100) {
			$("body").removeClass("is-scrolled");
		}
	});

	//managing alert banner	
	if ($('.alert-banner').length) {
		$('.alert-banner').on('click', '.alert-banner__toggle', function(e) {
			$(this).parent('.alert-banner').toggleClass('collapsed').find('.alert-banner__container')
				.slideToggle('fast',
					function() {
						fixHeaderPos();
					});
			document.cookie = "alertClosed=true; expires=0; path=/";
		});
		$(document).on('scroll', function() {
			if ($(window).width() < 1201 &&
				$('body').hasClass('is-scrolled') &&
				!$('.alert-banner').hasClass('collapsed')) {
				$('.alert-banner').addClass('collapsed').find('.alert-banner__container').slideUp('fast',
					function() {
						fixHeaderPos();
					});
			}
		});

		function fixHeaderPos() {
			var bannerHeight = $('.alert-banner').height(),
				headerHeight = bannerHeight + $('.site-header').height();
			
			$('.site-header').css('top', bannerHeight);

			if($('.breadcrumbs').length) {
				$('.breadcrumbs').css('padding-top', headerHeight + 10);
			} else {
				$('.site-container').css('padding-top', headerHeight);
			}
		}

		$(window).resize(function() {
			fixHeaderPos();
		});
		fixHeaderPos();
	}

	// removes any menu effect when a new item is selected
    jQuery('.trigger-button').click(function(){
		$('html').removeClass();
	});

	// Off Canvas Menu
	$(".bottom-tab-container").on('click', '.menu-trigger', function () {
		$('html').removeClass('search-open');
	    $('html').toggleClass('menu-open');
	    return false;
    });
    // Adding Main Manu mobile trigger code for the new HTML
    $(".site-header-controls").on('click', '.menu-trigger', function () {
        $('html').removeClass('search-open');
        $('html').toggleClass('menu-open');
        return false;
    });
    // Close Language Selector on Click outside of it
    $(document).click(function (e) {
        if ($(e.target).closest('.country-region-overlay').length < 1) {
            $("html").removeClass("country-language-open");
        }
    });
    // Copy Language Selector button text to mobile trigger button
    $(document).ready(function () {
        var languageSelectorText = $('.copy-to-mobile').text();
        $('.language-trigger-mobile-text').text(languageSelectorText);
	});
 	// Submenu accordion in site-header-menu menu
	$('.main-menu .toggle').click(function () {
		//Expand or collapse this panel
		$(this).next().slideToggle(200).toggleClass('open').parent().toggleClass('active');
		//Hide the other panels
		$(".site-header-menu .sub-menu").not($(this).next()).slideUp(200).removeClass('open').parent().removeClass('active');
    });
    $('.sub-menu-item.has-children .sub-toggle').click(function () {
        //Expand or collapse this panel
        $(this).next().slideToggle(200).toggleClass('open').parent().toggleClass('active');
        $('.sub-menu-item .open').not($(this).next()).slideUp(200).removeClass('open').parent().removeClass('active');
    });

    $('.sub-menu-item.link.has-children a.sub-link').hover(function () {
        var subMenu = $(this).parent().find('ul');
        var bottom = $(this).offset().top + $(this).height();
        var subMenuBottom = subMenu.offset().top + subMenu.height();

        if (subMenuBottom < bottom) subMenu.height(bottom - subMenu.offset().top);
    });

	$(".nohref > a").removeAttr("href");

	//toggle tooltip
	$('.tooltip-toggle').click(function() {
		$(this).parent().find('.nozzle-tooltip').slideDown(200);
		$(this).parent().find('.nozzle-tooltip-rule').addClass('active');
		$(this).closest('.main-content').find('.tooltip-overlay').addClass('open');
		$(this).preventDefault;
	});

	$(document).mouseup(function (e) {
		var container = $(".nozzle-tooltip");
		if (!container.is(e.target)
			   && container.has(e.target).length === 0)
			 {
			   container.slideUp(200);
			   container.find('.nozzle-tooltip-rule').removeClass('active');
			   container.closest('.main-content').find('.tooltip-overlay').removeClass('open');
			 }
		});

	$('.tooltip-close').click(function(){
		$(this).parent().slideUp(200);
		$(this).parent().find('.nozzle-tooltip-rule').removeClass('active');
		$(this).closest('.main-content').find('.tooltip-overlay').removeClass('open');
    });

    //hide/show nozzle filters
    $('.filter-text').click(function () {
        $('.nozzle-filters-form').slideToggle(300).toggleClass('collapsed');
        $('.nozzle-menu-item i').toggleClass('collapsed');
        $('.filter-text').toggleClass('collapsed');
    });

    $('.menu-filters').find('.icon-filter').click(function () {
        $('.nozzle-filters-form').slideToggle(300).toggleClass('collapsed');
        $('.nozzle-menu-item i').toggleClass('collapsed');
        $('.filter-text').toggleClass('collapsed');
    });

    // parametric search scrolling
    var filterPos = $('.nozzle-filters-menu-container');
    if (filterPos.length) {
        var stickyFilterPos = filterPos.offset().top;
        $(window).scroll(function () {
            if ($(window).scrollTop() > (stickyFilterPos)) {
                filterPos.addClass('affix');
                $('.nozzle-filters-menu').find('.icon-filter').addClass('collapsed');
                $('.nozzle-filters-menu').find('.filter-text').addClass('collapsed');
            }
            else {
                filterPos.removeClass('affix');
                $('.nozzle-filters-menu').find('.icon-filter').removeClass('collapsed');
                $('.nozzle-filters-menu').find('.filter-text').removeClass('collapsed');
                if ($('.nozzle-filters-form').hasClass('collapsed')) {
                    $('.nozzle-filters-menu').find('.filter-text').addClass('collapsed');
                    $('.nozzle-filters-menu').find('.icon-filter').addClass('collapsed');
                }
            }
        });
        $('.menu-filters').click(function () {
            if ($('.nozzle-filters-form').hasClass('collapsed') && $('.nozzle-filters-menu-container').hasClass('affix')) {
                $('.nozzle-filters-form').slideToggle(300).toggleClass('collapsed');
            }
            if ($(window).innerWidth() < 1201 && $('.nozzle-filters-menu-container').hasClass('affix')) {
                $('html,body').animate({
                    scrollTop: filterPos.position().top
                });
            } else if ($(window).innerWidth() > 1200 && $('.nozzle-filters-menu-container').hasClass('affix')) {
                $('html,body').animate({
                    scrollTop: filterPos.position().top - 90
                });
            }
        });
    }

	//select border-bottom blue 
	$(".spray-form-field select").change(function(){
		$(this).addClass("product-filter-active");
	});

	//checkout process js

	//pay with different card slideout open
	$(".cc-change").click(function(){
		$('.card-menu-wrapper').toggleClass('active');
		$('body').toggleClass('overlay-active');
	});

	//pay with different card slideout-close
	$(".close-search-button").click(function (){
		$(".card-menu-wrapper").removeClass('active');
		$('body').removeClass('overlay-active');
	});

	$('.checkout-menu-toggle-back').click(function(){
		$(".card-menu-wrapper").removeClass('active');
		$('body').removeClass('overlay-active');
	});
	$('.overlay').click(function(){
		$('body').removeClass('overlay-active');
		$(".card-menu-wrapper").removeClass('active');
	});

	//hide/show credit-cards-add
	$('.checkout-menu-toggle').click(function(){
		$('.filter-text').toggleClass('active');
		$('.card-add-form-wrapper.add-new').slideToggle(300).toggleClass('active');
		$('.card-menu-toggle i').toggleClass('icon-circle-plus').toggleClass('icon-circle-minus');
	});

	$('.checkout-edit-toggle').click(function(){
		$(this).toggleClass('active');
		if ($('.card-add-form-wrapper').hasClass('edit')) {
			$(this).parent().next().slideToggle(300).toggleClass('active');
		}
	});


	//change billing addresses slideout open
	$(".billing-change").click(function(){
		$('.billing-menu-wrapper').toggleClass('active');
		$('body').toggleClass('overlay-active');
	});

	//change billing addresses slideout close
	$(".close-search-button").click(function (){
		$(".billing-menu-wrapper").removeClass('active');
		$('body').removeClass('overlay-active');
	});
	$('.checkout-menu-toggle-back').click(function(){
		$(".billing-menu-wrapper").removeClass('active');
		$('body').removeClass('overlay-active');
	});
	$('.overlay').click(function(){
		$('body').removeClass('overlay-active');
		$(".billing-menu-wrapper").removeClass('active');
	});

	//hide/show add billing address
	$('.checkout-menu-toggle').click(function(){
		$('.filter-text').toggleClass('active');
		$('.billing-add-form-wrapper').slideToggle(300).toggleClass('active');
		$('.checkout-menu-toggle i').toggleClass('icon-circle-plus').toggleClass('icon-circle-minus');
	});

	//change shipping addresses slideout open
	$(".shipping-change-link").click(function(){
		$('.shipping-menu-wrapper').toggleClass('active');
		$('body').toggleClass('overlay-active');
	});

	//change shipping addresses slideout close
	$(".close-search-button").click(function (){
		$(".shipping-menu-wrapper").removeClass('active');
		$('body').removeClass('overlay-active');
	});
	$('.checkout-menu-toggle-back').click(function(){
		$(".shipping-menu-wrapper").removeClass('active');
		$('body').removeClass('overlay-active');
	});
	$('.overlay').click(function(){
		$('body').removeClass('overlay-active');
		$(".shipping-menu-wrapper").removeClass('active');
	});

	//hide/show add shipping address
	$('.checkout-menu-toggle').click(function(){
		$('.filter-text').toggleClass('active');
		$('.shipping-add-form-wrapper').slideToggle(300).toggleClass('active');
	});


	// parametric search scrolling
	var checkoutPos =  $('.buttonAffixPos');
	var checkoutBtn = $('.checkout-top-button');
	if(checkoutPos.length) {
		var stickyCheckoutPos = checkoutPos.offset().top;
		function checkoutPosOffset() {
		var stickyCheckoutPos = checkoutPos.offset().top;
	}
	window.onresize = function() {
		checkoutPosOffset();
	}
	$(window).scroll(function() {
		if ($(window).scrollTop() > stickyCheckoutPos) {
			checkoutBtn.addClass('affix');
		}
		else {
			checkoutBtn.removeClass('affix');
		}
	});
	}

	//blockquotes slick slider
	$('.blockquote-slider-container').slick({
		dots: true,
		autoplay: true,
		nextArrow: '<i class="icon-arrow right"></i>',
		prevArrow: '<i class="icon-arrow left"></i>',
	});

	//careers scroll to #
	$( "a.scroll-link" ).click(function( event ) {
		event.preventDefault();
		if ($(window).innerWidth() < 1201 ) {
			$("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top }, 500);
		} else {
			$("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top - 50 }, 500);
		}
	});
	
	// site search overlay
	var searchInput = $('.search-input');
	function inputFocus() {
		searchInput.focus();
	}
	$(".search-trigger").click(function () {
		$('html').removeClass('menu-open');
		$('html').toggleClass('search-open');
	    setTimeout(inputFocus, 200);
	    return false;
	});
	$(".close-search-button").click(function () {
		$('html').removeClass();
	});

	$("input[type='checkbox']").on("change", function() {
	    $(this).attr('checked',this.checked);
	    $(this).parent().toggleClass('checkbox-checked');
    });

    // Languages dropdown
    $(".country-language-trigger").click(function () {
        $('html').removeClass('menu-open');
        $('html').toggleClass('country-language-open');
        return false;
    });

    // Languages Slides toggle
    $('.do-acc').find('.acc-toggle').click(function () {
        if (!$(this).hasClass('active')) {
            //Expand or collapse this panel
            $(this).next().slideToggle('fast');
            $(this).toggleClass("active");

            //Hide the other panels
            $(".acc-content").not($(this).next()).slideUp('fast');
            $(".acc-toggle").not($(this)).removeClass("active");
        }
        else {
            $(this).next().slideUp('fast');
            $(this).removeClass("active");
        }
    });

    // Hide Language Dropdown button if no Languages on the site
    if ($(".menu-button .country-language-trigger").length < 1) {
        $(".country-language-trigger").hide();
    }

	// resource filters
	$(".close-filter-button, .resource-filters-overlay").click(function () {
		$('html').removeClass('filter-open');
		$('.resource-filters').slideUp(300);
	});
	$(".resource-filters-section-toggle").click(function() {
		$(this).parent().toggleClass('active');
		$(this).next().stop().slideToggle(200);
	});
	$(".parent-checkbox").on("change", function() {
		$(this).parent().next().slideToggle(200);
    });

    function resourceFilters() {
        $('main').on('click', '.filter-trigger', function () {
            var gridHeader = $(".resource-grid-header").offset();
            if ((window).innerWidth < 768) {
                $("html, body").animate({
                    scrollTop: gridHeader.top
                }, 300).delay(300)
                    .toggleClass('filter-fixed')
                    .find('.resource-grid-header').toggleClass('affix');
            }
            $('html').toggleClass('filter-open');
            setTimeout(function () {
                if ((window).innerWidth < 768) {
                    $('.resource-filters').slideToggle(300);
                    $('.event-filters').slideToggle(300);
                }
            }, 300);
        });
    }
	resourceFilters();
	//jQuery(window).on('resize', resourceFilters);

	//event filters
	$(".close-filter-button, .event-filters-overlay").click(function () {
		$('html').removeClass('filter-open');
		$('.event-filters').slideUp(300);
	});
    $(".event-filters-section-toggle").click(function () {
			$(this).toggleClass('active');
			$(this).next().stop().slideToggle(200);
    });

	//tankjet filters
	$(".close-filter-button, .tankjet-filters-overlay").click(function () {
		$('html').removeClass('filter-open');
		$('.tankjet-filters').slideUp(300);
	});
	$(".tankjet-filters-section-toggle").click(function() {
		$(this).parent().toggleClass('active');
		$(this).next().stop().slideToggle(200);
	});

	//tankjet content filters show mobile
	$('.tankjet-navigation-toggle').click( function() {
		var navHeader = $(".tankjet-navigation-link").offset();
		$("html, body").animate({
			scrollTop: navHeader.top
		}, 300).delay(300)
			.toggleClass('tankjet-filter-fixed');
		$('html').toggleClass('tankjet-filter-open');
		setTimeout(function() {
		 	if( (window).innerWidth < 1200) {
					$('.tankjet-filters').slideToggle(300);
		 	} 
		}, 300);
	});

	//close tankjet filters mobile menu 
	$('.tankjet-filters').find('.close-search-button').click(function(){
		var topPage = $('body').offset();
		$('html').removeClass();
		$('body').removeClass('tankjet-filter-fixed');
		$('.tankjet-filters').slideToggle(300);
		$('html, body').animate({
			scrollTop: topPage.top
		}, 300).delay(300);
    });
    

	// order card
	$(".order-card-toggle").click(function() {
		$(this).parent().toggleClass('order-card-active');
		if(window.innerWidth < 768) {
			$(this).parent().find('.order-hide').slideToggle(200);
		} else {
			$(this).parent().find('.order-num-links').slideToggle(200);
		}
	});
	$(".ship-title").click(function() {
		$(this).parent().parent().toggleClass('ship-to-active');
		$(this).next().slideToggle(200);
	});
	$(".add-notifications, .edit-notifications").click(function() {
		$(this).parent().parent().parent().next().find('.set-reminder').slideUp(200);
		$(this).parent().prev('.reminder-alert').removeClass('reminder-active');
		$(this).parent().toggleClass('notifications-active').parent().parent()
			.next('.order-card-drawer-container')
			.find('.set-notifications').slideToggle(200);
	});
	$(".add-reminder, .edit-reminder").click(function() {
		$(this).parent().parent().parent().next().find('.set-notifications').slideUp(200);
		$(this).parent().next('.notification-alert').removeClass('notifications-active');
		$(this).parent().toggleClass('reminder-active').parent().parent()
			.next('.order-card-drawer-container')
			.find('.set-reminder').slideToggle(200);
	});
	$(".cancel-reminder-button").click(function() {
		$(this).parent().parent().parent().slideToggle(200);
		$('.reminder-alert').removeClass('reminder-active');
	});
	$(".cancel-notifications-button").click(function() {
		$(this).parent().parent().parent().slideToggle(200);
		$('.notification-alert').removeClass('notifications-active');
	});

	// set reminder form (inside order card)
	$("select[name=reminder-timing]").on('change', function() {
		if(this.value == 'Custom') {
			$(this).parent().parent().addClass('reminder-timing-active');
		} else {
			$(this).parent().parent().removeClass('reminder-timing-active');
		}
	});
	$(".set-reminder-button").click(function() {
		$(this).parent().parent().fadeOut(200)
		.prev(".set-reminder-success").slideToggle(400);
	});
	$(".set-notifications-button").click(function() {
		$(this).parent().parent().fadeOut(200)
		.prev(".set-notifications-success").slideToggle(400);
    });

    // order filters
    function orderFilters() {
        $(".order-filters-header").find(".filter-trigger").click(function () {
            $('html').toggleClass('order-filter-open');
            $('.order-sort-container').slideToggle(300);
        });
        $(".order-search-trigger").click(function () {
            $('html').toggleClass('order-search-open');
            $('.order-search').slideToggle(300);
        });
    }
    orderFilters();

	$("#order-search").on("input", function() {
	   $(this).next().addClass('order-search-active');
	});
	$('.order-search-input-icon').click(function() {
		$("#order-search").val('').trigger('change').focus();
		$(this).removeClass('order-search-active');
	});
    
	// full message close
	$('.close-message').click(function() {
		$(this).parent().fadeOut();
	});
    
	$('[data-fancybox]').fancybox({
		focus: false
	});
    
	/**
	* cards toggle
	* accordion behavior of cards when viewed on mobile
	*/
	var cardSlideToggle = function () {

		$('main').on('click', '.js-toggle-container', function(e) {
			var _this = $(e.currentTarget);

			_this
				.parent()
				.toggleClass('active');

            _this
                .parent()
                .find('.js-slide')
                .stop()
                .slideToggle(350);

            _this
                .parent()
                .find('.js-fade')
                .stop()
                .fadeToggle(350);
		});
	}
    cardSlideToggle();

    var industryCardsNegMargin = function () {

        var container = jQuery('.dynamic-neg-margin'),
            images = jQuery('.cards-industries-grid-item-image-container', container),
            headerFeature = jQuery('.header-feature'),
            imageHeight = images.innerHeight(),
            midView = window.matchMedia('(min-width: 768px) and (max-width: 1439px)'),
            largeView = window.matchMedia('(min-width: 1440px)');

        if (midView.matches) {
            var bottomPaddingMid = imageHeight + 40;
            container.css('margin-top', '-' + imageHeight + 'px');
            headerFeature.css('padding-bottom', bottomPaddingMid);
        } else if (largeView.matches) {
            var bottomPaddingLg = imageHeight + 30;
            container.css('margin-top', '-' + imageHeight + 'px');
            headerFeature.css('padding-bottom', bottomPaddingLg);
        } else {
            container.css('margin-top', '0');
            headerFeature.css('padding-bottom', '30px');
        }
    }
	industryCardsNegMargin();
	$(window).on('resize', industryCardsNegMargin);
       
    var showMoreCards = function () {

        var button = $('.cards-industries-grid-callout-action a'),
            hiddenGrid = $('.card-grid-hidden'),
            initialGrid = $('.cards-industries-grid:first-of-type');

        button.on('click', function () {
            var _this = jQuery(this);

            hiddenGrid
                .toggleClass('card-grid-closed')
                .toggleClass('card-grid-open');

            _this.hide();
            if (_this.is('.button-more')) {
                jQuery('.button-less').show();
            } else {
                jQuery('.button-more').show();
            }

            return false;
        });
    };
    showMoreCards();

    var rangeSlider = function () {
        var slider = $('.range-slider'),
            range = $('.range-slider__range'),
            angle = $('.spray-angle-range'),
            value = $('.range-slider__value'),
            minus = $('.range-slider__minus'),
            plus = $('.range-slider__plus');
        initial = $('.range-slider__initial');

        slider.each(function () {

            value.each(function () {
                var value = range.attr('value');
                $(this).val(value);
            });

            value.on('change input', function () {
                range.val(this.value);
                angle.attr('data-angle', this.value);
            });

            initial.on('change input', function () {
                value.val(this.value);
                range.val(this.value);
                angle.attr('data-angle', this.value);
            });

            range.on('change input', function () {
                $(this).parent().prev().find(value).val(this.value);
                angle.attr('data-angle', this.value);
                initial.removeClass('show');
                value.addClass('show');
            });

            minus.click(function () {
                range.val(parseInt(range.val()) - 10);
                range.trigger('change');
                initial.removeClass('show');
                value.addClass('show');
            });

            plus.click(function () {
                range.val(parseInt(range.val()) + 10);
                range.trigger('change');
                initial.removeClass('show');
                value.addClass('show');
            });

        });
    };
    rangeSlider();

	//nozzle-finder-guided-selling smooth scroll to results
	if ($('body').hasClass('guided-selling')) {
		$('.spray-pattern-item').removeClass('selected');
		if (window.innerWidth < 768) {
			$('html, body').animate({
				scrollTop: $('#results').offset().top - 50
				} , 'slow');
		} else if (window.innerWidth > 1200) {
			$('html, body').animate({
				scrollTop: $('#results').offset().top - 90
				} , 'slow');
		} else {
			$('html, body').animate({
				scrollTop: $('#results').offset().top -20
				} , 'slow');
		}
		$('.menu-reset').click(function(){
			$('.disabled-overlay').slideUp(300);
		});
	}

	/* IE compatibility for Object-fit: contain via class '.thumbnail-container img' */
	if (!Modernizr.objectfit) {
		images = document.querySelectorAll('.thumbnail-container img');
		for (i = 0; i < images.length; i++) {
			element = images[i];
			$(element).addClass('object-fit-contain-ie');
			$(element).css('background-image', 'url(' + element.getAttribute('src') + ')');
			element.removeAttribute('src');
		}
	}
	
	extendHeader();
});

$(window).on('resize', function() {
	extendHeader();
});


function isSupportStepUpDown() {
	var i = document.createElement('input');
	i.setAttribute('type', 'number');
	i.setAttribute('step', '1');
	i.value = 1;

	try {
		i.stepUp();
	} catch(e) {
		return false;
	}

	return i.value == '2';
}

function isScrolled(){
	if($(body).hasClass('.is-scrolled')){
		return true;
	} 
}

function OnlyLatestRequestCallback() {
	var requestCount = 0,
		ajaxRequest = null;

	return {
		ajax: function(req) {
			var successCallback = req.success,
				count = ++requestCount;

			req.success = function(data) {
				if (count !== requestCount || !successCallback) {
					// do nothing if there is a more recent request
					return;
				}
				successCallback(data);
			};

			if (ajaxRequest) {
				// reap ongoing call
				ajaxRequest.abort();
			}

            return ajaxRequest = $.ajax(req);
        }
	}
}

function extendHeader() {
	var headerWidth = $('.site-header');
	var menuWidth = $('.main-menu-container ul').outerWidth();
	var searchWidth = $('.site-search-overlay').outerWidth();

	if($(window).width() > 1200) {
		if (menuWidth > (headerWidth.width() - searchWidth)) {
			headerWidth.addClass('long-menu');
		} else {
			headerWidth.removeClass('long-menu');
		}
	} else {
		headerWidth.removeClass('long-menu');
	}
}
