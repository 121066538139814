$(document).ready(function () {
	// process module
	$('.process__content').slick({
		arrows: true,
		dots: false,
		infinite: true,
		slidesToShow: 1,
  		slidesToScroll: 1,
		speed: 200,
		swipe: false,
		fade: true,
		autoplay: false,
		adaptiveHeight: true,
		prevArrow:"<div class='icon-arrow icon-arrow--left'>",
		nextArrow:"<div class='icon-arrow icon-arrow--right'>"
	});
	$('.process__content').on('afterChange', function(event, slick, currentSlide) {
		activeSlide = currentSlide + 1;
		grid = $('.process__scene');
		grid.attr('id', 'point' + activeSlide + '-active');
	});
	$('[data-point]').click(function(e) {
	   e.preventDefault();
	   var slideno = $(this).data('point');
	   $('.process__content').slick('slickGoTo', slideno - 1);
	});
});